/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from "react-intl";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from '../../setup'

export function UserInformation() {
  const intl = useIntl()
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.INFORMATION'})}</PageTitle>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            {/*<h3 className='fw-bolder m-0'>Thông tin cá nhân</h3>*/}
          </div>

          <div className='p-5'>
            <Link to='/update-information' className='btn btn-primary align-self-center mx-2'>
              Sửa thông tin cá nhân
            </Link>

            <Link to='/change-password' className='btn btn-primary align-self-center mx-2'>
              Đổi mật khẩu
            </Link>
          </div>

        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Họ và tên</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{user.name}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Tên đăng nhập</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{user.username}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>
              Số điện thoại
            </label>

            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bolder fs-6 me-2'>{user.phone}</span>

              {/*<span className='badge badge-success'>Đã xác minh</span>*/}
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Email</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{user.email}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Meta access token</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>
                {user.meta_access_token ? user.meta_access_token.substring(0, 20) + '...' : 'Chưa có, vui lòng cập nhật'}
              </span>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}
