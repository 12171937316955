import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from "react-intl";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from '../../setup'
import axios from "axios";
import {API_URL} from '../../app/modules/auth/redux/AuthCRUD'

const UpdateUserInformation: React.FC = () => {
  const intl = useIntl()
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  console.log({user})

  const initialValues = {
    name: user.name,
    username: user.username,
    phone: user.phone,
    email: user.email,
    meta_access_token: user.meta_access_token,
  }

  const profileDetailsSchema = Yup.object().shape({
    name: Yup.string()
      .min(6, 'Tối thiểu 6 ký tự')
      .max(40, 'Tối đa 40 ký tự')
      .required('Vui lòng nhập họ và tên'),
    username: Yup.string()
      .min(6, 'Tối thiểu 6 ký tự')
      .max(40, 'Tối đa 40 ký tự')
      .required('Vui lòng nhập tên đăng nhập'),
    phone: Yup.string()
      .min(6, 'Tối thiểu 6 ký tự')
      .max(40, 'Tối đa 40 ký tự')
      .required('Vui lòng nhập số điện thoại'),
    email: Yup.string()
      .email('Vui lòng nhập đúng định dạng email')
      .min(6, 'Tối thiểu 6 ký tự')
      .max(40, 'Tối đa 40 ký tự')
      .required('Vui lòng nhập email'),
  })

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      axios.post(`${API_URL}/user/update-information`, {
        name: values.name,
        phone: values.phone,
        email: values.email,
        meta_access_token: values.meta_access_token,
      })
        .then(({data}) => {
          setLoading(false)
          if (data.error_code === 1) {
            setStatus('Cập nhật thông tin thành công, trở về dashboard sau 3 giây')
            setTimeout(() => {
              window.location.replace('/dashboard')
            }, 3000)
          } else {
            setSubmitting(false)
            setStatus(data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          }
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        })
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.EDIT_INFORMATION'})}</PageTitle>

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Thông tin cá nhân</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Tên đăng nhập</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        readOnly={true}
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 bg-secondary'
                        placeholder=''
                        {...formik.getFieldProps('username')}
                      />
                      {formik.touched.username && formik.errors.username && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.username}</div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Họ và tên</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder=''
                        {...formik.getFieldProps('name')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.name}</div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Số điện thoại</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder=''
                        {...formik.getFieldProps('phone')}
                      />
                      {formik.touched.phone && formik.errors.phone && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.phone}</div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder=''
                        {...formik.getFieldProps('email')}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.email}</div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>Meta access token</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder=''
                        {...formik.getFieldProps('meta_access_token')}
                      />
                      {formik.touched.meta_access_token && formik.errors.meta_access_token && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.meta_access_token}</div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>

              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}


            </div>



            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
                {!loading && 'Lưu lại'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {UpdateUserInformation}
