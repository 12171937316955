import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useHistory} from 'react-router-dom'
import {useFormik} from 'formik'
import {API_URL} from '../redux/AuthCRUD'
import axios from "axios";

const initialValues = {
  email: '',
}

const initialValuesChangePassword = {
  otp: '',
  password: '',
  password_confirm: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Vui lòng nhập đúng định dạng email')
    .min(6, 'Tối thiểu 6 ký tự')
    .max(40, 'Tối đa 40 ký tự')
    .required('Vui lòng nhập email'),
})

const changePasswordSchema = Yup.object().shape({
  otp: Yup.string()
    .min(6, 'Tối thiểu 6 ký tự')
    .max(6, 'Tối đa 6 ký tự')
    .required('Vui lòng nhập otp'),
  password: Yup.string()
    .min(6, 'Tối thiểu 6 ký tự')
    .max(40, 'Tối đa 40 ký tự')
    .required('Vui lòng nhập mật khẩu'),
  password_confirm: Yup.string()
    .required('Vui lòng nhập xác nhận mật khẩu')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Mật khẩu và xác nhận mật khẩu không giống nhau, vui lòng kiểm tra lại"),
    }),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [isSentEmail, setIsSentEmail] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const history = useHistory()

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      axios.post(`${API_URL}/user/send-email-otp`, {
        email: values.email,
      })
        .then(({data}) => {
          setLoading(false)
          if (data.error_code === 1) {
            setIsSentEmail(true)
            setHasErrors(false)
            setLoading(false)
          } else {
            setSubmitting(false)
            setStatus(data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          }
        })
        .catch(() => {
          setLoading(false)
          setHasErrors(true)
          setSubmitting(false)
          setStatus('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        })
    },
  })

  const formikChangePassword = useFormik({
    initialValues: initialValuesChangePassword,
    validationSchema: changePasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      axios.post(`${API_URL}/user/forgot-password`, {
        email: formik.values.email,
        otp: values.otp,
        new_password: values.password,
      })
        .then(({data}) => {
          setLoading(false)
          if (data.error_code === 1) {
            setHasErrors(false)
            setStatus('Đổi mật khẩu thành công, trở về trang đăng nhập sau 3 giây')
            setTimeout(() => {
              history.push('/auth/login')
            }, 3000)
          } else {
            setSubmitting(false)
            setStatus(data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          }
        })
        .catch(() => {
          setLoading(false)
          setHasErrors(true)
          setSubmitting(false)
          setStatus('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        })
    },
  })

  return (
    <>
      {!isSentEmail &&
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Quên mật khẩu ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Nhập email của bạn để lấy lại mật khẩu.</div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Email</label>
          <input
            type='email'
            placeholder=''
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Xác nhận</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
              Vui lòng chờ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              Quay lại
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
      }

      {isSentEmail &&
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formikChangePassword.handleSubmit}
      >
        <div className='text-center mb-10'>


          {/* begin::Link */}
          <div className='text-gray-400 fw-bold fs-4'>Nhập OTP và mật khẩu mới.</div>
          {/* end::Link */}
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Otp</label>
          <input
            type='text'
            placeholder=''
            autoComplete='off'
            {...formikChangePassword.getFieldProps('otp')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formikChangePassword.touched.otp && formikChangePassword.errors.otp},
              {
                'is-valid': formikChangePassword.touched.otp && !formikChangePassword.errors.otp,
              }
            )}
          />
          {formikChangePassword.touched.otp && formikChangePassword.errors.otp && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formikChangePassword.errors.otp}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Mật khẩu mới</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formikChangePassword.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formikChangePassword.touched.password && formikChangePassword.errors.password},
              {
                'is-valid': formikChangePassword.touched.password && !formikChangePassword.errors.password,
              }
            )}
          />
          {formikChangePassword.touched.password && formikChangePassword.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formikChangePassword.errors.password}</span>
              </div>
            </div>
          )}
        </div>

        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Xác nhận mật khẩu mới</label>
          <input
            type='password'
            placeholder=''
            autoComplete='off'
            {...formikChangePassword.getFieldProps('password_confirm')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formikChangePassword.touched.password_confirm && formikChangePassword.errors.password_confirm},
              {
                'is-valid': formikChangePassword.touched.password_confirm && !formikChangePassword.errors.password_confirm,
              }
            )}
          />
          {formikChangePassword.touched.password_confirm && formikChangePassword.errors.password_confirm && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formikChangePassword.errors.password_confirm}</span>
              </div>
            </div>
          )}
        </div>

        {formikChangePassword.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formikChangePassword.status}</div>
          </div>
        )}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formikChangePassword.isSubmitting || !formikChangePassword.isValid}
          >
            {!loading && <span className='indicator-label'>Xác nhận</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
              Vui lòng chờ...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg btn-light-primary fw-bolder'
              disabled={formikChangePassword.isSubmitting || !formik.isValid}
            >
              Quay lại
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
      }
    </>
  )
}
