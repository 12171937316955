/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../_metronic/layout/core'
import {Tab, Tabs} from 'react-bootstrap-v5';
import axios from "axios";
import {LOGIN_URL, API_URL} from "../modules/auth/redux/AuthCRUD";
import moment from 'moment';

const AdsManager: FC = () => {
  const intl = useIntl()

  const [itemPerPage, setItemPerPage] = useState('25')
  const [page, setPage] = useState('1')
  const [pages, setPages] = useState('')

  const [adaccounts, setAdaccounts] = useState([])
  const [campaigns, setCampaigns] = useState([])
  const [adsets, setAdsets] = useState([])
  const [ads, setAds] = useState([])

  const [campaignsView, setCampaignsView] = useState([])
  const [adsetsView, setAdsetsView] = useState([])
  const [adsView, setAdsView] = useState([])

  const [pickedAdaccounts, setPickedAdaccounts] = useState([])
  const [pickedCampaigns, setPickedCampaigns] = useState([])
  const [pickedAdsets, setPickedAdsets] = useState([])

  // console.log('pickedAdaccounts', pickedAdaccounts)
  // console.log('pickedCampaigns', pickedCampaigns)
  // console.log('pickedAdsets', pickedAdsets)

  // console.log(itemPerPage)
  // console.log('adsetsView', adsetsView)

  const [errorMsg, setErrorMsg] = useState('')

  const getData = () => {
    axios.post(`${API_URL}/user/request-ad-data`, {})
      .then(({data}) => {
        if (data.error_code === 1) {
          const userData = data.data.user
          setAdaccounts(userData.adaccounts ?? [])
          setCampaigns(userData.campaigns ?? [])
          setAdsets(userData.adsets ?? [])
          setAds(userData.ads ?? [])
        } else {
          console.log(data.data)
          setErrorMsg( data.data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        }
      })
      .catch((err) => {
        console.log('catch error', err)
        setErrorMsg( 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
      })
  }

  useEffect(() => {
    // todo nếu đã chọn tài khoản thì lấy camp, adset, ad thuộc tài khoản
    let campaignsAfterPickAdaccount = []
    let adsetsAfterPickAdaccount = []
    let adsAfterPickAdaccount = []
    if (pickedAdaccounts.length > 0) {
      // lay camp thuoc account

      campaignsAfterPickAdaccount = campaigns.filter((campaign: any) => {
        for (let adaccountId of pickedAdaccounts) {
          if (campaign.adaccount_id === adaccountId) {
            return true
          }
        }
        return false
      })

      adsetsAfterPickAdaccount = adsets.filter((adset: any) => {
        for (let adaccountId of pickedAdaccounts) {
          if (adset.adaccount_id === adaccountId) {
            return true
          }
        }
        return false
      })

      adsAfterPickAdaccount = ads.filter((ad: any) => {
        for (let adaccountId of pickedAdaccounts) {
          if (ad.adaccount_id === adaccountId) {
            return true
          }
        }
        return false
      })
    } else {
      campaignsAfterPickAdaccount = [...campaigns]
      adsetsAfterPickAdaccount = [...adsets]
      adsAfterPickAdaccount = [...ads]
    }
    // console.log('campaignsAfterPickAdaccount', campaignsAfterPickAdaccount)
    // console.log('adsetsAfterPickAdaccount', adsetsAfterPickAdaccount)
    // console.log('adsAfterPickAdaccount', adsAfterPickAdaccount)

    // todo nếu đã chọn camp thì lấy adset, ads thuộc camp
    let adsetsAfterPickCampaign: any = []
    let adsAfterPickCampaign: any = []
    if (pickedCampaigns.length > 0) {
      adsetsAfterPickCampaign = adsetsAfterPickAdaccount.filter((adset: any) => {
        for (let campaignId of pickedCampaigns) {
          if (adset.campaign_id === campaignId) {
            return true
          }
        }
        return false
      })
      adsAfterPickCampaign = adsAfterPickAdaccount.filter((ad: any) => {
        for (let campaignId of pickedCampaigns) {
          if (ad.campaign_id === campaignId) {
            return true
          }
        }
        return false
      })
    } else {
      adsetsAfterPickCampaign = [...adsetsAfterPickAdaccount]
      adsAfterPickCampaign = [...adsAfterPickAdaccount]
    }
    // console.log('adsetsAfterPickCampaign', adsetsAfterPickCampaign)
    // console.log('adsAfterPickCampaign', adsAfterPickCampaign)

    // todo nếu đã chọn adset thì lấy ads thuộc adset
    let adsAfterPickAdset = []
    if (pickedAdsets.length > 0) {
      adsAfterPickAdset = adsAfterPickCampaign.filter((ad: any) => {
        for (let adsetId of pickedAdsets) {
          if (ad.adset_id === adsetId) {
            return true
          }
        }
        return false
      })
    } else {
      adsAfterPickAdset = [...adsAfterPickCampaign]
    }
    // console.log('adsAfterPickAdset', adsAfterPickAdset)

    // todo lấy các phần active trước, paused sau
    let activeCampaignAfterPickAdaccount = campaignsAfterPickAdaccount.filter((campaign: any) => {
      return campaign.status == 'ACTIVE'
    })
    let pausedCampaignAfterPickAdaccount = campaignsAfterPickAdaccount.filter((campaign: any) => {
      return campaign.status == 'PAUSED'
    })
    // console.log('activeCampaignAfterPickAdaccount', activeCampaignAfterPickAdaccount)
    // console.log('pausedCampaignAfterPickAdaccount', pausedCampaignAfterPickAdaccount)
    const campaignsAfterStatus = [...activeCampaignAfterPickAdaccount, ...pausedCampaignAfterPickAdaccount]
    // console.log('campaignsAfterStatus', campaignsAfterStatus)

    let activeAdsetAfterPickCampaign = adsetsAfterPickCampaign.filter((adset: any) => {
      return adset.status == 'ACTIVE'
    })
    let pausedAdsetAfterPickCampaign = adsetsAfterPickCampaign.filter((adset: any) => {
      return adset.status == 'PAUSED'
    })
    const adsetAfterStatus = [...activeAdsetAfterPickCampaign, ...pausedAdsetAfterPickCampaign]
    console.log('adsetAfterStatus', adsetAfterStatus)

    let activeAdAfterPickAdset = adsAfterPickAdset.filter((ad: any) => {
      return ad.status == 'ACTIVE'
    })
    let pausedAdAfterPickAdset = adsAfterPickAdset.filter((ad: any) => {
      return ad.status == 'PAUSED'
    })
    const adAfterStatus = [...activeAdAfterPickAdset, ...pausedAdAfterPickAdset]
    // console.log('adAfterStatus', adAfterStatus)

    // todo lấy theo item per page

    const campaignsView: any = campaignsAfterStatus.slice(0, parseInt(itemPerPage))
    const adsetsView: any = adsetAfterStatus.slice(0, parseInt(itemPerPage))
    const adsView: any = adAfterStatus.slice(0, parseInt(itemPerPage))
    setCampaignsView(campaignsView)
    setAdsetsView(adsetsView)
    setAdsView(adsView)
  }, [adaccounts, campaigns, adsets, ads, itemPerPage, pickedAdaccounts, pickedCampaigns, pickedAdsets])

  useEffect(() => {
    // call api lay thong tin quang cao
    getData()
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.ADS_MANAGER'})}</PageTitle>
      <div className='row gy-5 g-xl-8'>
        <div className='col-xxl-12'>
          <Tabs
            defaultActiveKey="ad-account"
            transition={false}
            id="noanim-tab-example"
            className="mb-3"
          >
            <Tab eventKey="ad-account" title="Ad accounts">
              <div className='table-responsive' style={{ height: '70vh', scrollbarColor: '#999 white' }}>
                <table className="table table-bordered table-rounded table-striped border gy-7 gs-7 bg-white">
                  <thead>
                  <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                    <th className='py-2'></th>
                    <th className='py-2'>Tài khoản quảng cáo</th>
                    <th className='py-2'>Trạng thái tài khoản</th>
                    <th className='py-2'>Loại tài khoản</th>
                    <th className='py-2'>Tiền tệ</th>
                    <th className='py-2'>Trạng thái đồng bộ</th>
                  </tr>
                  </thead>
                  <tbody>
                  {adaccounts.length > 0 && <>
                    {adaccounts.map((adaccount: any) => {
                      const currentUnixTime = moment().unix()
                      const lastSyncAt = parseInt(adaccount.last_sync_at)
                      const differenceInMinutes = moment.unix(currentUnixTime).diff(moment.unix(lastSyncAt), 'minutes');
                      return <tr key={adaccount.id}>
                        <td className='py-2'>
                          <div className='form-check form-check-custom form-check-solid mx-1 align-middle'>
                            <input
                              className='form-check-input' type='checkbox' value=''
                              onChange={(e) => {
                                console.log('checkbox ', e.target.checked, adaccount.adaccount_id)
                                const adaccountId: any = adaccount.adaccount_id
                                const checkStatus = e.target.checked
                                let existInPicker = false
                                if (pickedAdaccounts.length > 0) {
                                  for (const pickedAdaccount of pickedAdaccounts) {
                                    if (pickedAdaccount === adaccountId) {
                                      existInPicker = true
                                    }
                                  }
                                }
                                if (checkStatus) {
                                  if (!existInPicker) {
                                    const newPickedAdaccounts: any = [...pickedAdaccounts]
                                    newPickedAdaccounts.push(adaccountId)
                                    setPickedAdaccounts(newPickedAdaccounts)
                                  }
                                  // thêm vào mảng đã chọn
                                } else {
                                  // xóa khỏi mảng đã chọn
                                  if (existInPicker) {
                                    const newPickedAdaccounts: any = pickedAdaccounts.filter((element) => {
                                      return element !== adaccountId
                                    })
                                    setPickedAdaccounts(newPickedAdaccounts)
                                  }
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td className='py-2'>
                          <span className='fw-bold'>{adaccount.name}</span>
                          <br/>
                          <span className=''>{adaccount.adaccount_id}</span>
                        </td>
                        <td className='py-2'>
                          {adaccount.status && <span className='text-success fw-bold'>Hoạt động</span>}
                          {!adaccount.status && <span className='text-danger fw-bold'>Tạm dừng</span>}
                        </td>
                        <td className='py-2'>
                          Doanh nghiệp
                        </td>
                        <td className='py-2'>
                          {adaccount.currency ?? 'VND'}
                        </td>
                        <td className='py-2'>
                          <span className='text-success'>Hoàn tất</span>
                          <br/>
                          <span className='fs-8'>Đã đồng bộ {differenceInMinutes} phút trước</span>
                        </td>
                      </tr>
                    })}
                  </>
                  }
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="campaigns" title="Campaigns">
              <div className='table-responsive' style={{ height: '70vh', scrollbarColor: '#999 white' }}>
                <table className="table table-rounded table-striped border gy-7 gs-7 bg-white">
                  <thead>
                  <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                    <th className='py-2'></th>
                    <th className='py-2'></th>
                    <th className='py-2'>Chiến dịch</th>
                    <th className='py-2'>Tài khoản quảng cáo</th>
                    <th className='py-2'>Trạng thái</th>
                    <th className='py-2'>Phân phối</th>
                    <th className='py-2'>Purchase</th>
                    <th className='py-2'>Website purchase</th>
                    <th className='py-2'>Meta purchase</th>
                    <th className='py-2'>Amount spent</th>
                    <th className='py-2'>Cost per result</th>
                    <th className='py-2'>Cost per messaging</th>
                    <th className='py-2'>Messaging convert</th>
                    <th className='py-2'>CPM</th>
                    <th className='py-2'>CPC all</th>
                    <th className='py-2'>CTR all</th>
                    <th className='py-2'>Reach</th>
                    <th className='py-2'>Frequency</th>
                    <th className='py-2'>Impressions</th>
                    <th className='py-2'>Link clicks</th>
                    <th className='py-2'>CPC link clicks</th>
                    <th className='py-2'>CTR link clicks</th>
                    <th className='py-2'>Clicks all</th>
                  </tr>
                  </thead>
                  <tbody>
                  {campaignsView.length > 0 && <>
                    {campaignsView.map((campaign: any) => {
                      return <tr key={campaign.id}>
                        <td className='py-2'>
                          <div className='form-check form-check-custom form-check-solid mx-1'>
                            <input
                              className='form-check-input' type='checkbox' value=''
                              onChange={(e) => {
                                const campaignId: any = campaign.campaign_id
                                const checkStatus = e.target.checked
                                let existInPicker = false
                                if (pickedCampaigns.length > 0) {
                                  for (const pickedCampaign of pickedCampaigns) {
                                    if (pickedCampaign === campaignId) {
                                      existInPicker = true
                                    }
                                  }
                                }
                                if (checkStatus) {
                                  if (!existInPicker) {
                                    const newPickedCampaign: any = [...pickedCampaigns]
                                    newPickedCampaign.push(campaignId)
                                    setPickedCampaigns(newPickedCampaign)
                                  }
                                  // thêm vào mảng đã chọn
                                } else {
                                  // xóa khỏi mảng đã chọn
                                  if (existInPicker) {
                                    const newPickedCampaign: any = pickedCampaigns.filter((element) => {
                                      return element !== campaignId
                                    })
                                    setPickedCampaigns(newPickedCampaign)
                                  }
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td className='py-2'>
                          <div className='form-check form-check-solid form-switch fv-row'>
                            <input
                              className='form-check-input w-45px h-30px'
                              type='checkbox'
                              checked={campaign.status === 'ACTIVE'}
                              onChange={(e) => {
                                e.preventDefault()
                                console.log('switch ', e.target.checked)
                                if (window.confirm(`Bạn có chắc chắn muốn ${e.target.checked ? 'bật' : 'tắt'} chiến dịch này?`)) {
                                  axios.post(`${API_URL}/user/change-status`, {
                                    id: campaign.campaign_id,
                                    status: e.target.checked ? 'ACTIVE' : 'PAUSED',
                                  })
                                    .then(({data}) => {
                                      if (data.error_code === 1) {
                                        let newCampaigns = [...campaigns]
                                        for (let i = 0; i < newCampaigns.length; i++) {
                                          // @ts-ignore
                                          if (newCampaigns[i].campaign_id === campaign.campaign_id) {
                                            // @ts-ignore
                                            newCampaigns[i].status = newCampaigns[i].status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE'
                                          }
                                        }
                                        setCampaigns(newCampaigns)
                                      } else {
                                        console.log(data.data)
                                        setErrorMsg( data.data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                                      }
                                    })
                                    .catch((err) => {
                                      console.log('catch error', err)
                                      setErrorMsg( 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                                    })
                                }
                              }}
                            />
                            <label className='form-check-label'></label>
                          </div>
                        </td>

                        <td className='py-2'>
                          <span className='fw-bold'>{campaign.name}</span>
                          <br/>
                          <span className=''>{campaign.campaign_id}</span>
                        </td>

                        <td className='py-2'>
                          <span className='fw-bold'>{campaign.adaccount?.name ?? ''}</span>
                          <br/>
                          <span className=''>{campaign.adaccount?.adaccount_id ?? ''}</span>
                        </td>

                        <td className='py-2'>
                          {campaign.status}
                        </td>

                        <td className='py-2'>
                          {campaign.status === 'ACTIVE' && <span className='text-success fw-bold'>Hoạt động</span>}
                          {!(campaign.status === 'ACTIVE') && <span className='text-danger fw-bold'>Tạm dừng</span>}
                        </td>

                        <td className='py-2'>
                          {campaign.purchase ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.website_purchase ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.meta_purchase ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.amount_spent ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.cost_per_result ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.cost_per_messaging ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.messaging_convert ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.cpm ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.cpc_all ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.ctr_all ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.reach ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.frequency ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.impression ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.link_click ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.cpc_link_click ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.ctr_link_click ?? ''}
                        </td>

                        <td className='py-2'>
                          {campaign.click_all ?? ''}
                        </td>

                      </tr>
                    })}
                  </>
                  }
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="ad-sets" title="Ad sets">
              <div className='table-responsive' style={{ height: '70vh', scrollbarColor: '#999 white' }}>
                <table className="table table-rounded table-striped border gy-7 gs-7 bg-white">
                  <thead>
                  <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                    <th className='py-2'></th>
                    <th className='py-2'></th>
                    <th className='py-2'>Nhóm quảng cáo</th>
                    <th className='py-2'>Chiến dịch</th>
                    <th className='py-2'>Tài khoản quảng cáo</th>
                    <th className='py-2'>Trạng thái</th>
                    <th className='py-2'>Phân phối</th>
                    <th className='py-2'>Purchase</th>
                    <th className='py-2'>Website purchase</th>
                    <th className='py-2'>Meta purchase</th>
                    <th className='py-2'>Amount spent</th>
                    <th className='py-2'>Cost per result</th>
                    <th className='py-2'>Cost per messaging</th>
                    <th className='py-2'>Messaging convert</th>
                    <th className='py-2'>CPM</th>
                    <th className='py-2'>CPC all</th>
                    <th className='py-2'>CTR all</th>
                    <th className='py-2'>Reach</th>
                    <th className='py-2'>Frequency</th>
                    <th className='py-2'>Impressions</th>
                    <th className='py-2'>Link clicks</th>
                    <th className='py-2'>CPC link clicks</th>
                    <th className='py-2'>CTR link clicks</th>
                    <th className='py-2'>Clicks all</th>
                  </tr>
                  </thead>
                  <tbody>
                  {adsetsView.length > 0 && <>
                    {adsetsView.map((adset: any) => {
                      return <tr key={adset.id}>
                        <td className='py-2'>
                          <div className='form-check form-check-custom form-check-solid mx-1'>
                            <input
                              className='form-check-input' type='checkbox' value=''
                              onChange={(e) => {
                                const adsetId: any = adset.adset_id
                                const checkStatus = e.target.checked
                                let existInPicker = false
                                if (pickedAdsets.length > 0) {
                                  for (const pickedAdset of pickedAdsets) {
                                    if (pickedAdset === adsetId) {
                                      existInPicker = true
                                    }
                                  }
                                }
                                if (checkStatus) {
                                  if (!existInPicker) {
                                    const newPickedAdset: any = [...pickedAdsets]
                                    newPickedAdset.push(adsetId)
                                    setPickedAdsets(newPickedAdset)
                                  }
                                  // thêm vào mảng đã chọn
                                } else {
                                  // xóa khỏi mảng đã chọn
                                  if (existInPicker) {
                                    const newPickedAdset: any = pickedAdsets.filter((element) => {
                                      return element !== adsetId
                                    })
                                    setPickedAdsets(newPickedAdset)
                                  }
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td className='py-2'>
                          <div className='form-check form-check-solid form-switch fv-row'>
                            <input
                              className='form-check-input w-45px h-30px'
                              type='checkbox'
                              checked={adset.status === 'ACTIVE'}
                              onChange={(e) => {
                                e.preventDefault()
                                console.log('switch ', e.target.checked)
                                if (window.confirm(`Bạn có chắc chắn muốn ${e.target.checked ? 'bật' : 'tắt'} chiến dịch này?`)) {
                                  axios.post(`${API_URL}/user/change-status`, {
                                    id: adset.adset_id,
                                    status: e.target.checked ? 'ACTIVE' : 'PAUSED',
                                  })
                                    .then(({data}) => {
                                      if (data.error_code === 1) {
                                        let newAdsets = [...adsets]
                                        for (let i = 0; i < newAdsets.length; i++) {
                                          // @ts-ignore
                                          if (newAdsets[i].adset_id === adset.adset_id) {
                                            // @ts-ignore
                                            newAdsets[i].status = newAdsets[i].status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE'
                                          }
                                        }
                                        setAdsets(newAdsets)
                                      } else {
                                        console.log(data.data)
                                        setErrorMsg( data.data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                                      }
                                    })
                                    .catch((err) => {
                                      console.log('catch error', err)
                                      setErrorMsg( 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                                    })
                                }
                              }}
                            />
                            <label className='form-check-label'></label>
                          </div>
                        </td>

                        <td className='py-2'>
                          <span className='fw-bold'>{adset.name}</span>
                          <br/>
                          <span className=''>{adset.adset_id}</span>
                        </td>

                        <td className='py-2'>
                          <span className='fw-bold'>{adset.campaign?.name ?? ''}</span>
                          <br/>
                          <span className=''>{adset.campaign?.campaign_id ?? ''}</span>
                        </td>

                        <td className='py-2'>
                          <span className='fw-bold'>{adset.adaccount?.name ?? ''}</span>
                          <br/>
                          <span className=''>{adset.adaccount?.adaccount_id ?? ''}</span>
                        </td>

                        <td className='py-2'>
                          {adset.status}
                        </td>

                        <td className='py-2'>
                          {adset.status === 'ACTIVE' && <span className='text-success fw-bold'>Hoạt động</span>}
                          {!(adset.status === 'ACTIVE') && <span className='text-danger fw-bold'>Tạm dừng</span>}
                        </td>

                        <td className='py-2'>
                          {adset.purchase ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.website_purchase ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.meta_purchase ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.amount_spent ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.cost_per_result ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.cost_per_messaging ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.messaging_convert ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.cpm ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.cpc_all ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.ctr_all ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.reach ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.frequency ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.impression ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.link_click ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.cpc_link_click ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.ctr_link_click ?? ''}
                        </td>

                        <td className='py-2'>
                          {adset.click_all ?? ''}
                        </td>

                      </tr>
                    })}
                  </>
                  }
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab eventKey="ads" title="Ads">
              <div className='table-responsive' style={{ height: '70vh', scrollbarColor: '#999 white' }}>
                <table className="table table-rounded table-striped border gy-7 gs-7 bg-white">
                  <thead>
                  <tr className="fw-bold fs-6 text-gray-800 border-bottom border-gray-200">
                    <th className='py-2'></th>
                    <th className='py-2'>Quảng cáo</th>
                    <th className='py-2'>Nhóm quảng cáo</th>
                    <th className='py-2'>Chiến dịch</th>
                    <th className='py-2'>Tài khoản quảng cáo</th>
                    <th className='py-2'>Trạng thái</th>
                    <th className='py-2'>Phân phối</th>
                    <th className='py-2'>Purchase</th>
                    <th className='py-2'>Website purchase</th>
                    <th className='py-2'>Meta purchase</th>
                    <th className='py-2'>Amount spent</th>
                    <th className='py-2'>Cost per result</th>
                    <th className='py-2'>Cost per messaging</th>
                    <th className='py-2'>Messaging convert</th>
                    <th className='py-2'>CPM</th>
                    <th className='py-2'>CPC all</th>
                    <th className='py-2'>CTR all</th>
                    <th className='py-2'>Reach</th>
                    <th className='py-2'>Frequency</th>
                    <th className='py-2'>Impressions</th>
                    <th className='py-2'>Link clicks</th>
                    <th className='py-2'>CPC link clicks</th>
                    <th className='py-2'>CTR link clicks</th>
                    <th className='py-2'>Clicks all</th>
                  </tr>
                  </thead>
                  <tbody>
                  {adsView.length > 0 && <>
                    {adsView.map((ad: any) => {
                      return <tr key={ad.id}>
                        {/*<td className='py-2'>*/}
                        {/*  <div className='form-check form-check-custom form-check-solid mx-1'>*/}
                        {/*    <input*/}
                        {/*      className='form-check-input' type='checkbox' value=''*/}
                        {/*      onChange={(e) => {*/}
                        {/*        console.log('checkbox ', e.target.checked)*/}
                        {/*      }}*/}
                        {/*    />*/}
                        {/*  </div>*/}
                        {/*</td>*/}
                        <td className='py-2'>
                          <div className='form-check form-check-solid form-switch fv-row'>
                            <input
                              className='form-check-input w-45px h-30px'
                              type='checkbox'
                              checked={ad.status === 'ACTIVE'}
                              onChange={(e) => {
                                e.preventDefault()
                                console.log('switch ', e.target.checked)
                                if (window.confirm(`Bạn có chắc chắn muốn ${e.target.checked ? 'bật' : 'tắt'} chiến dịch này?`)) {
                                  axios.post(`${API_URL}/user/change-status`, {
                                    id: ad.ad_id,
                                    status: e.target.checked ? 'ACTIVE' : 'PAUSED',
                                  })
                                    .then(({data}) => {
                                      if (data.error_code === 1) {
                                        let newAds = [...ads]
                                        for (let i = 0; i < newAds.length; i++) {
                                          // @ts-ignore
                                          if (newAds[i].adset_id === ad.adset_id) {
                                            // @ts-ignore
                                            newAds[i].status = newAds[i].status === 'ACTIVE' ? 'PAUSED' : 'ACTIVE'
                                          }
                                        }
                                        setAdsets(newAds)
                                      } else {
                                        console.log(data.data)
                                        setErrorMsg( data.data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                                      }
                                    })
                                    .catch((err) => {
                                      console.log('catch error', err)
                                      setErrorMsg( 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
                                    })
                                }
                              }}
                            />
                            <label className='form-check-label'></label>
                          </div>
                        </td>

                        <td className='py-2'>
                          <span className='fw-bold'>{ad.name}</span>
                          <br/>
                          <span className=''>{ad.ad_id}</span>
                        </td>

                        <td className='py-2'>
                          <span className='fw-bold'>{ad.adset?.name}</span>
                          <br/>
                          <span className=''>{ad.adset?.adset_id}</span>
                        </td>

                        <td className='py-2'>
                          <span className='fw-bold'>{ad.campaign?.name ?? ''}</span>
                          <br/>
                          <span className=''>{ad.campaign?.campaign_id ?? ''}</span>
                        </td>

                        <td className='py-2'>
                          <span className='fw-bold'>{ad.adaccount?.name ?? ''}</span>
                          <br/>
                          <span className=''>{ad.adaccount?.adaccount_id ?? ''}</span>
                        </td>

                        <td className='py-2'>
                          {ad.status}
                        </td>

                        <td className='py-2'>
                          {ad.status === 'ACTIVE' && <span className='text-success fw-bold'>Hoạt động</span>}
                          {!(ad.status === 'ACTIVE') && <span className='text-danger fw-bold'>Tạm dừng</span>}
                        </td>

                        <td className='py-2'>
                          {ad.purchase ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.website_purchase ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.meta_purchase ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.amount_spent ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.cost_per_result ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.cost_per_messaging ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.messaging_convert ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.cpm ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.cpc_all ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.ctr_all ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.reach ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.frequency ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.impression ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.link_click ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.cpc_link_click ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.ctr_link_click ?? ''}
                        </td>

                        <td className='py-2'>
                          {ad.click_all ?? ''}
                        </td>

                      </tr>
                    })}
                  </>
                  }
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
          <div>
            <div className="row justify-content-between">
              <div className='col-2'>
                <select
                  className='form-select form-select-solid text-center'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-allow-clear='true'
                  value={itemPerPage}
                  onChange={(e) => setItemPerPage(e.target.value)}
                >
                  <option value='25'>25</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                  <option value='1000'>1000</option>
                </select>
              </div>
              <div className="col-2">
                {/*<ul className="pagination">*/}
                {/*  <li className="page-item active"><a className="page-link" href="#">1</a></li>*/}
                {/*  <li className="page-item"><a className="page-link" href="#">2</a></li>*/}
                {/*  <li className="page-item"><a className="page-link" href="#">3</a></li>*/}
                {/*</ul>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {AdsManager}
