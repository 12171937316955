import axios from 'axios'
import {AuthModel} from '../models/AuthModel'
import {UserModel} from '../models/UserModel'

export const API_URL = process.env.BACKEND_URL || 'https://backend.nvrg.vn/api'
// 'https://backend.nvrg.vn/api'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/request-data`
export const LOGIN_URL = `${API_URL}/user/login`
export const REGISTER_URL = `${API_URL}/user/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string) {
  return axios.post<AuthModel>(REGISTER_URL, {
    email,
    firstname,
    lastname,
    password,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.post(GET_USER_BY_ACCESSTOKEN_URL)
  //return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
