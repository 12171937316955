import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {PageTitle} from '../../_metronic/layout/core'
import {useIntl} from "react-intl";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from '../../setup'
import axios from "axios";
import {API_URL} from '../../app/modules/auth/redux/AuthCRUD'
import {useHistory} from "react-router-dom";

const ChangePassword: React.FC = () => {
  const intl = useIntl()
  const history = useHistory()
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual)

  const initialValues = {
    old_password: '',
    new_password: '',
  }

  const profileDetailsSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(6, 'Tối thiểu 6 ký tự')
      .max(40, 'Tối đa 40 ký tự')
      .required('Vui lòng nhập mật khẩu cũ'),
    new_password: Yup.string()
      .min(6, 'Tối thiểu 6 ký tự')
      .max(40, 'Tối đa 40 ký tự')
      .required('Vui lòng nhập mật khẩu mới'),
  })

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setStatus('')
      axios.post(`${API_URL}/user/change-password`, {
        old_password: values.old_password,
        new_password: values.new_password,
      })
        .then(({data}) => {
          setLoading(false)
          if (data.error_code === 1) {
            setStatus('Đổi mật khẩu thành công, trở về dashboard sau 3 giây')
            setTimeout(() => {
              history.push('/')
            }, 3000)
          } else {
            setSubmitting(false)
            setStatus(data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          }
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        })
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.CHANGE_PASSWORD'})}</PageTitle>

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Đổi mật khẩu đăng nhập</h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Mật khẩu cũ</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder=''
                        {...formik.getFieldProps('old_password')}
                      />
                      {formik.touched.old_password && formik.errors.old_password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.old_password}</div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Mật khẩu mới</label>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-12 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder=''
                        {...formik.getFieldProps('new_password')}
                      />
                      {formik.touched.new_password && formik.errors.new_password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.new_password}</div>
                        </div>
                      )}
                    </div>

                  </div>
                </div>
              </div>


              {formik.status && (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              )}


            </div>



            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading || !formik.isValid}>
                {!loading && 'Lưu lại'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                  Vui lòng chờ...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export {ChangePassword}
