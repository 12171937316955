/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import * as auth from '../redux/AuthRedux'
import {REGISTER_URL} from '../redux/AuthCRUD'
import {Link, useHistory} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import axios from "axios";

const initialValues = {
  name: '',
  username: '',
  phone: '',
  email: '',
  password: '',
  password_confirm: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(6, 'Tối thiểu 6 ký tự')
    .max(40, 'Tối đa 40 ký tự')
    .required('Vui lòng nhập họ và tên'),
  username: Yup.string()
    .min(6, 'Tối thiểu 6 ký tự')
    .max(40, 'Tối đa 40 ký tự')
    .required('Vui lòng nhập tên đăng nhập'),
  phone: Yup.string()
    .min(6, 'Tối thiểu 6 ký tự')
    .max(40, 'Tối đa 40 ký tự')
    .required('Vui lòng nhập số điện thoại'),
  email: Yup.string()
    .email('Vui lòng nhập đúng định dạng email')
    .min(6, 'Tối thiểu 6 ký tự')
    .max(40, 'Tối đa 40 ký tự')
    .required('Vui lòng nhập email'),
  password: Yup.string()
    .min(6, 'Tối thiểu 6 ký tự')
    .max(40, 'Tối đa 40 ký tự')
    .required('Vui lòng nhập mật khẩu'),
  password_confirm: Yup.string()
    .required('Vui lòng nhập xác nhận mật khẩu')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Mật khẩu và xác nhận mật khẩu không giống nhau, vui lòng kiểm tra lại"),
    }),
  acceptTerms: Yup.bool().required('Bạn cần đồng ý với quy định bảo mật website'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [successMsg, setSuccessMsg] = useState('')
  const history = useHistory()
  const dispatch = useDispatch()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      axios.post(REGISTER_URL, {
        name: values.name,
        username: values.username,
        phone: values.phone,
        email: values.email,
        password: values.password,
      })
        .then(({data}) => {
          setLoading(false)
          if (data.error_code === 1) {
            setStatus('Đăng ký thành công, trở về trang đăng nhập sau 3 giây')
            setTimeout(() => {
              history.push('/auth/login')
            }, 3000)
          } else {
            setSubmitting(false)
            setStatus(data.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
          }
          //dispatch(auth.actions.login(accessToken))
        })
        .catch(() => {
          setLoading(false)
          setSubmitting(false)
          setStatus('Đã xảy ra lỗi, vui lòng liên hệ quản trị viên')
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Đăng ký tài khoản</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          Bạn đã có tài khoản?
          <Link to='/auth/forgot-password' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Quên mật khẩu?
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/*<button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button>*/}
      {/* end::Action */}

      {/*<div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>OR</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>*/}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Họ và tên</label>
        <input
          placeholder=''
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.name && formik.errors.name},
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Số điện thoại</label>
        <input
          placeholder=''
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.phone && formik.errors.phone},
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder=''
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Tên đăng nhập</label>
        <input
          placeholder=''
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('username')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.username && formik.errors.username},
            {
              'is-valid': formik.touched.username && !formik.errors.username,
            }
          )}
        />
        {formik.touched.username && formik.errors.username && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.username}</span>
            </div>
          </div>
        )}
      </div>

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Mật khẩu</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder=''
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Xác nhận mật khẩu</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('password_confirm')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password_confirm && formik.errors.password_confirm,
            },
            {
              'is-valid': formik.touched.password_confirm && !formik.errors.password_confirm,
            }
          )}
        />
        {formik.touched.password_confirm && formik.errors.password_confirm && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password_confirm}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            Tôi đồng ý với{' '}
            <Link to='/auth/terms' className='ms-1 link-primary'>
              Điều khoản bảo mật
            </Link>
            .
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Xác nhận</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Vui lòng chờ...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Quay lại
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
